/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 4.25.3
 * source: base/tag.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from "google-protobuf";
export class Tag extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        tag?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("tag" in data && data.tag != undefined) {
                this.tag = data.tag;
            }
        }
    }
    get tag() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set tag(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        tag?: string;
    }): Tag {
        const message = new Tag({});
        if (data.tag != null) {
            message.tag = data.tag;
        }
        return message;
    }
    toObject() {
        const data: {
            tag?: string;
        } = {};
        if (this.tag != null) {
            data.tag = this.tag;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.tag.length)
            writer.writeString(1, this.tag);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Tag {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Tag();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.tag = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Tag {
        return Tag.deserialize(bytes);
    }
}
