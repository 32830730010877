/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 4.25.3
 * source: base/resources.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./external_meta";
import * as dependency_2 from "./tag";
import * as pb_1 from "google-protobuf";
export class ExternalResource extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3]];
    constructor(data?: any[] | ({} & (({
        magnet?: Magnet;
        website?: never;
        share_dmhy_org?: never;
    } | {
        magnet?: never;
        website?: Website;
        share_dmhy_org?: never;
    } | {
        magnet?: never;
        website?: never;
        share_dmhy_org?: SHARE_DMHY_ORG;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("magnet" in data && data.magnet != undefined) {
                this.magnet = data.magnet;
            }
            if ("website" in data && data.website != undefined) {
                this.website = data.website;
            }
            if ("share_dmhy_org" in data && data.share_dmhy_org != undefined) {
                this.share_dmhy_org = data.share_dmhy_org;
            }
        }
    }
    get magnet() {
        return pb_1.Message.getWrapperField(this, Magnet, 1) as Magnet;
    }
    set magnet(value: Magnet) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
    }
    get has_magnet() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get website() {
        return pb_1.Message.getWrapperField(this, Website, 2) as Website;
    }
    set website(value: Website) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get has_website() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get share_dmhy_org() {
        return pb_1.Message.getWrapperField(this, SHARE_DMHY_ORG, 3) as SHARE_DMHY_ORG;
    }
    set share_dmhy_org(value: SHARE_DMHY_ORG) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0], value);
    }
    get has_share_dmhy_org() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get resources() {
        const cases: {
            [index: number]: "none" | "magnet" | "website" | "share_dmhy_org";
        } = {
            0: "none",
            1: "magnet",
            2: "website",
            3: "share_dmhy_org"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3])];
    }
    static fromObject(data: {
        magnet?: ReturnType<typeof Magnet.prototype.toObject>;
        website?: ReturnType<typeof Website.prototype.toObject>;
        share_dmhy_org?: ReturnType<typeof SHARE_DMHY_ORG.prototype.toObject>;
    }): ExternalResource {
        const message = new ExternalResource({});
        if (data.magnet != null) {
            message.magnet = Magnet.fromObject(data.magnet);
        }
        if (data.website != null) {
            message.website = Website.fromObject(data.website);
        }
        if (data.share_dmhy_org != null) {
            message.share_dmhy_org = SHARE_DMHY_ORG.fromObject(data.share_dmhy_org);
        }
        return message;
    }
    toObject() {
        const data: {
            magnet?: ReturnType<typeof Magnet.prototype.toObject>;
            website?: ReturnType<typeof Website.prototype.toObject>;
            share_dmhy_org?: ReturnType<typeof SHARE_DMHY_ORG.prototype.toObject>;
        } = {};
        if (this.magnet != null) {
            data.magnet = this.magnet.toObject();
        }
        if (this.website != null) {
            data.website = this.website.toObject();
        }
        if (this.share_dmhy_org != null) {
            data.share_dmhy_org = this.share_dmhy_org.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.has_magnet)
            writer.writeMessage(1, this.magnet, () => this.magnet.serialize(writer));
        if (this.has_website)
            writer.writeMessage(2, this.website, () => this.website.serialize(writer));
        if (this.has_share_dmhy_org)
            writer.writeMessage(3, this.share_dmhy_org, () => this.share_dmhy_org.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ExternalResource {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ExternalResource();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.magnet, () => message.magnet = Magnet.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.website, () => message.website = Website.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.share_dmhy_org, () => message.share_dmhy_org = SHARE_DMHY_ORG.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): ExternalResource {
        return ExternalResource.deserialize(bytes);
    }
}
export class SHARE_DMHY_ORG extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        magnet?: Magnet;
        author?: string;
        publish_timestamp?: number;
        title?: string;
        page_link?: string;
        tags?: dependency_2.Tag[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [7], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("magnet" in data && data.magnet != undefined) {
                this.magnet = data.magnet;
            }
            if ("author" in data && data.author != undefined) {
                this.author = data.author;
            }
            if ("publish_timestamp" in data && data.publish_timestamp != undefined) {
                this.publish_timestamp = data.publish_timestamp;
            }
            if ("title" in data && data.title != undefined) {
                this.title = data.title;
            }
            if ("page_link" in data && data.page_link != undefined) {
                this.page_link = data.page_link;
            }
            if ("tags" in data && data.tags != undefined) {
                this.tags = data.tags;
            }
        }
    }
    get magnet() {
        return pb_1.Message.getWrapperField(this, Magnet, 1) as Magnet;
    }
    set magnet(value: Magnet) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get has_magnet() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get author() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set author(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get publish_timestamp() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set publish_timestamp(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get title() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set title(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get page_link() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set page_link(value: string) {
        pb_1.Message.setField(this, 5, value);
    }
    get tags() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_2.Tag, 7) as dependency_2.Tag[];
    }
    set tags(value: dependency_2.Tag[]) {
        pb_1.Message.setRepeatedWrapperField(this, 7, value);
    }
    static fromObject(data: {
        magnet?: ReturnType<typeof Magnet.prototype.toObject>;
        author?: string;
        publish_timestamp?: number;
        title?: string;
        page_link?: string;
        tags?: ReturnType<typeof dependency_2.Tag.prototype.toObject>[];
    }): SHARE_DMHY_ORG {
        const message = new SHARE_DMHY_ORG({});
        if (data.magnet != null) {
            message.magnet = Magnet.fromObject(data.magnet);
        }
        if (data.author != null) {
            message.author = data.author;
        }
        if (data.publish_timestamp != null) {
            message.publish_timestamp = data.publish_timestamp;
        }
        if (data.title != null) {
            message.title = data.title;
        }
        if (data.page_link != null) {
            message.page_link = data.page_link;
        }
        if (data.tags != null) {
            message.tags = data.tags.map(item => dependency_2.Tag.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            magnet?: ReturnType<typeof Magnet.prototype.toObject>;
            author?: string;
            publish_timestamp?: number;
            title?: string;
            page_link?: string;
            tags?: ReturnType<typeof dependency_2.Tag.prototype.toObject>[];
        } = {};
        if (this.magnet != null) {
            data.magnet = this.magnet.toObject();
        }
        if (this.author != null) {
            data.author = this.author;
        }
        if (this.publish_timestamp != null) {
            data.publish_timestamp = this.publish_timestamp;
        }
        if (this.title != null) {
            data.title = this.title;
        }
        if (this.page_link != null) {
            data.page_link = this.page_link;
        }
        if (this.tags != null) {
            data.tags = this.tags.map((item: dependency_2.Tag) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.has_magnet)
            writer.writeMessage(1, this.magnet, () => this.magnet.serialize(writer));
        if (this.author.length)
            writer.writeString(2, this.author);
        if (this.publish_timestamp != 0)
            writer.writeUint64(3, this.publish_timestamp);
        if (this.title.length)
            writer.writeString(4, this.title);
        if (this.page_link.length)
            writer.writeString(5, this.page_link);
        if (this.tags.length)
            writer.writeRepeatedMessage(7, this.tags, (item: dependency_2.Tag) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SHARE_DMHY_ORG {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SHARE_DMHY_ORG();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.magnet, () => message.magnet = Magnet.deserialize(reader));
                    break;
                case 2:
                    message.author = reader.readString();
                    break;
                case 3:
                    message.publish_timestamp = reader.readUint64();
                    break;
                case 4:
                    message.title = reader.readString();
                    break;
                case 5:
                    message.page_link = reader.readString();
                    break;
                case 7:
                    reader.readMessage(message.tags, () => pb_1.Message.addToRepeatedWrapperField(message, 7, dependency_2.Tag.deserialize(reader), dependency_2.Tag));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): SHARE_DMHY_ORG {
        return SHARE_DMHY_ORG.deserialize(bytes);
    }
}
export class Magnet extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        url?: string;
        external_metas?: dependency_1.ExternalMeta[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("url" in data && data.url != undefined) {
                this.url = data.url;
            }
            if ("external_metas" in data && data.external_metas != undefined) {
                this.external_metas = data.external_metas;
            }
        }
    }
    get url() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set url(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get external_metas() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_1.ExternalMeta, 2) as dependency_1.ExternalMeta[];
    }
    set external_metas(value: dependency_1.ExternalMeta[]) {
        pb_1.Message.setRepeatedWrapperField(this, 2, value);
    }
    static fromObject(data: {
        url?: string;
        external_metas?: ReturnType<typeof dependency_1.ExternalMeta.prototype.toObject>[];
    }): Magnet {
        const message = new Magnet({});
        if (data.url != null) {
            message.url = data.url;
        }
        if (data.external_metas != null) {
            message.external_metas = data.external_metas.map(item => dependency_1.ExternalMeta.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            url?: string;
            external_metas?: ReturnType<typeof dependency_1.ExternalMeta.prototype.toObject>[];
        } = {};
        if (this.url != null) {
            data.url = this.url;
        }
        if (this.external_metas != null) {
            data.external_metas = this.external_metas.map((item: dependency_1.ExternalMeta) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.url.length)
            writer.writeString(1, this.url);
        if (this.external_metas.length)
            writer.writeRepeatedMessage(2, this.external_metas, (item: dependency_1.ExternalMeta) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Magnet {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Magnet();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.url = reader.readString();
                    break;
                case 2:
                    reader.readMessage(message.external_metas, () => pb_1.Message.addToRepeatedWrapperField(message, 2, dependency_1.ExternalMeta.deserialize(reader), dependency_1.ExternalMeta));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Magnet {
        return Magnet.deserialize(bytes);
    }
}
export class Website extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        url?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("url" in data && data.url != undefined) {
                this.url = data.url;
            }
        }
    }
    get url() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set url(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        url?: string;
    }): Website {
        const message = new Website({});
        if (data.url != null) {
            message.url = data.url;
        }
        return message;
    }
    toObject() {
        const data: {
            url?: string;
        } = {};
        if (this.url != null) {
            data.url = this.url;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.url.length)
            writer.writeString(1, this.url);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Website {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Website();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.url = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Website {
        return Website.deserialize(bytes);
    }
}
