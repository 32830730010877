/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 4.25.3
 * source: base/language_code.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from "google-protobuf";
export enum LanguageCode {
    ENG = 0,
    CHS = 1,
    CHT = 2,
    JPN = 3
}
