/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 4.25.3
 * source: base/external_meta.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from "google-protobuf";
export class ExternalMeta extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2]];
    constructor(data?: any[] | ({} & (({
        whats_link?: WhatsLink_Info;
        bangumi_tv?: never;
    } | {
        whats_link?: never;
        bangumi_tv?: Bangumi_TV;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("whats_link" in data && data.whats_link != undefined) {
                this.whats_link = data.whats_link;
            }
            if ("bangumi_tv" in data && data.bangumi_tv != undefined) {
                this.bangumi_tv = data.bangumi_tv;
            }
        }
    }
    get whats_link() {
        return pb_1.Message.getWrapperField(this, WhatsLink_Info, 1) as WhatsLink_Info;
    }
    set whats_link(value: WhatsLink_Info) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
    }
    get has_whats_link() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get bangumi_tv() {
        return pb_1.Message.getWrapperField(this, Bangumi_TV, 2) as Bangumi_TV;
    }
    set bangumi_tv(value: Bangumi_TV) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get has_bangumi_tv() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get external_meta() {
        const cases: {
            [index: number]: "none" | "whats_link" | "bangumi_tv";
        } = {
            0: "none",
            1: "whats_link",
            2: "bangumi_tv"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2])];
    }
    static fromObject(data: {
        whats_link?: ReturnType<typeof WhatsLink_Info.prototype.toObject>;
        bangumi_tv?: ReturnType<typeof Bangumi_TV.prototype.toObject>;
    }): ExternalMeta {
        const message = new ExternalMeta({});
        if (data.whats_link != null) {
            message.whats_link = WhatsLink_Info.fromObject(data.whats_link);
        }
        if (data.bangumi_tv != null) {
            message.bangumi_tv = Bangumi_TV.fromObject(data.bangumi_tv);
        }
        return message;
    }
    toObject() {
        const data: {
            whats_link?: ReturnType<typeof WhatsLink_Info.prototype.toObject>;
            bangumi_tv?: ReturnType<typeof Bangumi_TV.prototype.toObject>;
        } = {};
        if (this.whats_link != null) {
            data.whats_link = this.whats_link.toObject();
        }
        if (this.bangumi_tv != null) {
            data.bangumi_tv = this.bangumi_tv.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.has_whats_link)
            writer.writeMessage(1, this.whats_link, () => this.whats_link.serialize(writer));
        if (this.has_bangumi_tv)
            writer.writeMessage(2, this.bangumi_tv, () => this.bangumi_tv.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ExternalMeta {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ExternalMeta();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.whats_link, () => message.whats_link = WhatsLink_Info.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.bangumi_tv, () => message.bangumi_tv = Bangumi_TV.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): ExternalMeta {
        return ExternalMeta.deserialize(bytes);
    }
}
export class Bangumi_TV extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        url?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("url" in data && data.url != undefined) {
                this.url = data.url;
            }
        }
    }
    get url() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set url(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        url?: string;
    }): Bangumi_TV {
        const message = new Bangumi_TV({});
        if (data.url != null) {
            message.url = data.url;
        }
        return message;
    }
    toObject() {
        const data: {
            url?: string;
        } = {};
        if (this.url != null) {
            data.url = this.url;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.url.length)
            writer.writeString(1, this.url);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Bangumi_TV {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Bangumi_TV();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.url = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Bangumi_TV {
        return Bangumi_TV.deserialize(bytes);
    }
}
export class WhatsLink_Info extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        type?: string;
        file_type?: string;
        name?: string;
        size?: number;
        count?: number;
        screenshots?: WhatsLink_InfoWhatsLinkScreenshot[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [6], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("type" in data && data.type != undefined) {
                this.type = data.type;
            }
            if ("file_type" in data && data.file_type != undefined) {
                this.file_type = data.file_type;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("size" in data && data.size != undefined) {
                this.size = data.size;
            }
            if ("count" in data && data.count != undefined) {
                this.count = data.count;
            }
            if ("screenshots" in data && data.screenshots != undefined) {
                this.screenshots = data.screenshots;
            }
        }
    }
    get type() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set type(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get file_type() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set file_type(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get size() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set size(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get count() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set count(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get screenshots() {
        return pb_1.Message.getRepeatedWrapperField(this, WhatsLink_InfoWhatsLinkScreenshot, 6) as WhatsLink_InfoWhatsLinkScreenshot[];
    }
    set screenshots(value: WhatsLink_InfoWhatsLinkScreenshot[]) {
        pb_1.Message.setRepeatedWrapperField(this, 6, value);
    }
    static fromObject(data: {
        type?: string;
        file_type?: string;
        name?: string;
        size?: number;
        count?: number;
        screenshots?: ReturnType<typeof WhatsLink_InfoWhatsLinkScreenshot.prototype.toObject>[];
    }): WhatsLink_Info {
        const message = new WhatsLink_Info({});
        if (data.type != null) {
            message.type = data.type;
        }
        if (data.file_type != null) {
            message.file_type = data.file_type;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.size != null) {
            message.size = data.size;
        }
        if (data.count != null) {
            message.count = data.count;
        }
        if (data.screenshots != null) {
            message.screenshots = data.screenshots.map(item => WhatsLink_InfoWhatsLinkScreenshot.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            type?: string;
            file_type?: string;
            name?: string;
            size?: number;
            count?: number;
            screenshots?: ReturnType<typeof WhatsLink_InfoWhatsLinkScreenshot.prototype.toObject>[];
        } = {};
        if (this.type != null) {
            data.type = this.type;
        }
        if (this.file_type != null) {
            data.file_type = this.file_type;
        }
        if (this.name != null) {
            data.name = this.name;
        }
        if (this.size != null) {
            data.size = this.size;
        }
        if (this.count != null) {
            data.count = this.count;
        }
        if (this.screenshots != null) {
            data.screenshots = this.screenshots.map((item: WhatsLink_InfoWhatsLinkScreenshot) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.type.length)
            writer.writeString(1, this.type);
        if (this.file_type.length)
            writer.writeString(2, this.file_type);
        if (this.name.length)
            writer.writeString(3, this.name);
        if (this.size != 0)
            writer.writeInt64(4, this.size);
        if (this.count != 0)
            writer.writeInt64(5, this.count);
        if (this.screenshots.length)
            writer.writeRepeatedMessage(6, this.screenshots, (item: WhatsLink_InfoWhatsLinkScreenshot) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): WhatsLink_Info {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new WhatsLink_Info();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.type = reader.readString();
                    break;
                case 2:
                    message.file_type = reader.readString();
                    break;
                case 3:
                    message.name = reader.readString();
                    break;
                case 4:
                    message.size = reader.readInt64();
                    break;
                case 5:
                    message.count = reader.readInt64();
                    break;
                case 6:
                    reader.readMessage(message.screenshots, () => pb_1.Message.addToRepeatedWrapperField(message, 6, WhatsLink_InfoWhatsLinkScreenshot.deserialize(reader), WhatsLink_InfoWhatsLinkScreenshot));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): WhatsLink_Info {
        return WhatsLink_Info.deserialize(bytes);
    }
}
export class WhatsLink_InfoWhatsLinkScreenshot extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        time?: number;
        screenshot?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("time" in data && data.time != undefined) {
                this.time = data.time;
            }
            if ("screenshot" in data && data.screenshot != undefined) {
                this.screenshot = data.screenshot;
            }
        }
    }
    get time() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set time(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get screenshot() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set screenshot(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        time?: number;
        screenshot?: string;
    }): WhatsLink_InfoWhatsLinkScreenshot {
        const message = new WhatsLink_InfoWhatsLinkScreenshot({});
        if (data.time != null) {
            message.time = data.time;
        }
        if (data.screenshot != null) {
            message.screenshot = data.screenshot;
        }
        return message;
    }
    toObject() {
        const data: {
            time?: number;
            screenshot?: string;
        } = {};
        if (this.time != null) {
            data.time = this.time;
        }
        if (this.screenshot != null) {
            data.screenshot = this.screenshot;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.time != 0)
            writer.writeInt64(1, this.time);
        if (this.screenshot.length)
            writer.writeString(2, this.screenshot);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): WhatsLink_InfoWhatsLinkScreenshot {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new WhatsLink_InfoWhatsLinkScreenshot();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.time = reader.readInt64();
                    break;
                case 2:
                    message.screenshot = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): WhatsLink_InfoWhatsLinkScreenshot {
        return WhatsLink_InfoWhatsLinkScreenshot.deserialize(bytes);
    }
}
